import jwt from "jwt-decode";
import JsCookies from "js-cookie";

const IsDoctor = () => {
  let accessCookie;
  if (typeof window !== "undefined") {
    accessCookie = JsCookies.get(process.env.NEXT_PUBLIC_NEXTJS_COOKIE);
  }

  if (!accessCookie) return false;

  const accessCookieData = jwt(accessCookie);
  const role = accessCookieData.roles;

  return role === "doctor";
};

export default IsDoctor;

export const ERROR = "ERROR";
export const SUCCESS = "SUCCESS";

export const OPEN_CART = "OPEN_CART";
export const EMPTY_CART = "EMPTY_CART";
export const CLOSE_CART = "CLOSE_CART";
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";

export const INCREASE_CART_QUANTITY = "INCREASE_CART_QUANTITY";
export const DECREASE_CART_QUANTITY = "DECREASE_CART_QUANTITY";

export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const FETCH_PRODUCT_CATEGORIES = "FETCH_PRODUCT_CATEGORIES";
export const FETCH_SEARCHABLE_PRODUCTS = "FETCH_SEARCHABLE_PRODUCTS";

import * as types from "./types";
import {
  fetchAllProducts,
  fetchSearchableProducts,
} from "../services/products";

export const openCart = () => ({ type: types.OPEN_CART });
export const closeCart = () => ({ type: types.CLOSE_CART });

export const emptyCart = () => ({
  type: types.EMPTY_CART,
});
export const addToCart = (product) => ({
  type: types.ADD_TO_CART,
  payload: product,
});
export const removeFromCart = (productId) => ({
  type: types.REMOVE_FROM_CART,
  payload: productId,
});
export const increaseCartQuantity = (productId) => {
  return {
    type: types.INCREASE_CART_QUANTITY,
    payload: productId,
  };
};
export const decreaseCartQuantity = (productId) => ({
  type: types.DECREASE_CART_QUANTITY,
  payload: productId,
});

export const fetchProducts = () => async (dispatch) => {
  try {
    const products = await fetchAllProducts();
    dispatch({
      type: `${types.FETCH_PRODUCTS}_${types.SUCCESS}`,
      payload: { products },
    });
    return products;
  } catch (err) {
    console.log(err.message);
    dispatch({
      type: `${types.FETCH_PRODUCTS}_${types.ERROR}`,
      payload: { error: err.message },
    });
    return;
  }
};

export const fetchProductCategories = () => async (dispatch) => {
  let page = 1,
    response = [];
  try {
    while (true) {
      const res = await fetch(
        `${process.env.NEXT_PUBLIC_WORDPRESS_URL}/wp-json/wc/v2/products/categories?consumer_key=${process.env.WORDPRESS_CONSUMER_KEY}&consumer_secret=${process.env.WORDPRESS_CONSUMER_SECRET}&per_page=100&page=${page}`
      );
      const categories = await res.json();
      response = response.concat(categories);

      if (categories.length < 100) {
        break;
      }
      page += 1;
    }

    dispatch({
      type: `${types.FETCH_PRODUCT_CATEGORIES}_${types.SUCCESS}`,
      payload: { categories: response },
    });

    return response;
  } catch (err) {
    console.log(err);
    dispatch({
      type: `${types.FETCH_PRODUCT_CATEGORIES}_${types.ERROR}`,
      payload: { error: err.message },
    });
    return;
  }
};

export const fetchSearchableProductsAction = () => async (dispatch) => {
  try {
    const response = await fetchSearchableProducts();
    if (response && response.success && response.data) {
      dispatch({
        type: `${types.FETCH_SEARCHABLE_PRODUCTS}_${types.SUCCESS}`,
        payload: { searchableProducts: response.data },
      });
    } else {
      dispatch({
        type: `${types.FETCH_SEARCHABLE_PRODUCTS}_${types.ERROR}`,
        payload: { error: response.message },
      });
    }
  } catch (err) {
    dispatch({
      type: `${types.FETCH_SEARCHABLE_PRODUCTS}_${types.ERROR}`,
      payload: { error: err.message },
    });
  }
};
